import React from "react";
import { graphql } from "gatsby";

import globalStyles from "../components/styles";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Navigation from "../components/Navigation";

export default function Simple({ data }) {
    const classes = globalStyles();
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    return (
        <Layout header={ frontmatter.title }
            parentPath={ frontmatter.backlinkA }>
            <SEO title={ frontmatter.title } />
            <Navigation path={ frontmatter.path }/>
            <div className={ classes.blogPostContainer }>
                <div>
                    <div
                        dangerouslySetInnerHTML={{ __html: html }}
                    />

                </div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        backlinkA
        backlinkB
        backlinkC
        backlinkD
      }
      id
    }
  }
`;
